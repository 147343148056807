import { Component, OnInit, OnDestroy }   from '@angular/core';
import { ListService }       from '@omnilib/services/list.service';
import { LoadingService }    from '@omnipas/services/loading.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { PhotoService }      from '@omnipas/services/photo.service';
import { RouterService }     from '@omnipas/services/router.service';
import { environment }       from '@environments/omnipas/dev/environment';

@Component({
  selector: 'omnipas-photopermissions',
  templateUrl: './photopermissions.component.html'
})
export class PhotoPermissionsComponent implements OnInit, OnDestroy {
  environment = environment;

  initialized: boolean = false;

  countrycode: string = 'NL';
  licenseplate: string = '';

  constructor ( public list:         ListService
              , public loading:      LoadingService
              , public message:      MessageService
              , public pageheader:   PageHeaderService
              , public photo:        PhotoService
              , public router:       RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata || !environment.config.changepublishpermission ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.photopermissions', 'pagetext.photopermissions' );
      await this.photo.read();
    }

    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async submit() {
    await this.photo.save(true);
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  showpopup() {
    this.router.showpermissionsinfo = true;
  }

  ngOnDestroy() {
    return;
  }
}
