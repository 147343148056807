export const environment = {
  production: true,
  apiurl: 'https://soap2.omnicms3.nl:8443/OmniPasRestBam/api/v1/',
  mock: false,
  config: {
    product: "Mijn BAM-Pas",
    customer_fullname: "BAM",
    customer_shortname: "BAM",
    url: "",
    phone: "",
    email: "",
    MFAemail: false,
    MFAsms: false,
    logintype : "database",
    autologin: false,
    username: "",
    password: "",
    samlConfig: {},
    surfConfig: {},
    adfsConfig: {},
    ingenico: {},
//    cardprices: { "Student": 1500, "Employee": 1000, "External employee": 1000, "default": 1250 },
    cardprices: { "Student": 0, "Employee": 0, "External employee": 0, "default": 0 },
    languages: [
      {
        id: 1,
        LanguageCode: "nl",
        LanguageName: "Nederlands",
        Card: "pas"
      },
      {
        id: 2,
        LanguageCode: "en",
        LanguageName: "English",
        Card: "pass"
      },
    ],
    nameoncardformats: [
      { id: 1, format: "<<Initials>> <<MiddleName>> <<LastName>>" },
      { id: 2, format: "<<FirstName>> <<MiddleName>> <<LastName>>" },
      { id: 3, format: "<<LastName>>, <<FirstName>> <<MiddleName>>" }
    ],
    savephotoonserver: false,
    changepublishpermission: false,
    chargingcard: false,
    digitalcard: false,
    uploadphoto: true,
    webcam: true,
    licenseplate: false,
    nameoncard: false,
    cardpreview: true,
    cardaslink: false,
    cardresult: false,
    cardactivate: true,
    cardblock: true,
    carddeblock: false,
    cardrequest: true,
    logoutbutton: false,
    requestnewpassword: true,
    publishPermission: true,
    scrollButtons: true,
    breadcrumbs: false,
    versioninfooter: false,
    photoResizeToWidth: 480,
    photoMiniatureWidth: 240,
    togglefont: false,
    photorequirements: false,
    firstnameonwelcome: true,
    photoAgreedNotOnFirstPage: true,
    deliveryaddress: false,
    langimage : false,
    maintainance: false,
    timeout: 180,
  },
  ssl: true
};
