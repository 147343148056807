import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DataService }          from '@omnilib/services/data.service';
import { IconsService }         from '@omnipas/services/icons.service';
import { LanguageService }      from '@omnipas/services/language.service';
import { ListService }          from '@omnilib/services/list.service';
import { LoadingService }       from '@omnipas/services/loading.service';
import { RouterService }        from '@omnipas/services/router.service';
import { environment }          from '@environments/omnipas/dev/environment';

@Component({
  selector: 'omnicard-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  environment = environment;

  initialized:   boolean = false;
  showUpArrow:   boolean = false;
  showDownArrow: boolean = false;

  constructor( public data:           DataService
             , public icons:          IconsService
             , public list:           ListService
             , public loading:        LoadingService
             , public router:         RouterService
             ) {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    let body        = document.getElementById('body');
    let main        = document.getElementById('main');
    let toosmall    = document.getElementById('toosmall');

    this.router.toosmall = window.innerWidth < 280;

    if ( window.innerWidth < 280 ) {
      body.classList.remove("wide");
      body.classList.remove("small");
      body.classList.add("toosmall");
    } else if ( window.innerWidth < 650 ) {
      body.classList.remove("toosmall");
      body.classList.remove("wide");
      body.classList.add("small");
    } else {
      body.classList.remove("toosmall");
      body.classList.remove("small");
      body.classList.add("wide");
    }

    if ( !this.router.showdigitalcard ) {
      this.router.mainwidth  = main.offsetWidth;
      this.router.mainheight = main.offsetHeight;

      this.showScrollArrows();
    }
  }

  @HostListener('window:scroll', ['$event'])
  showScrollArrows() {
    this.showUpArrow = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20;
    this.showDownArrow = document.body.scrollTop < document.body.scrollHeight - document.body.clientHeight || document.documentElement.scrollTop < document.documentElement.scrollHeight - document.documentElement.clientHeight;
  }

  @HostListener('window:beforeunload', ['$event'])
  cleanUp($event) {
  }

  async ngOnInit(){
    await this.initialize();
  }

  async initialize() {
    //let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    //favIcon.href = 'assets/images/favicon.ico';

    this.router.toggleshowwait(true);

    if ( this.list.emptyList( this.router.persondata ) ) {
      await this.loading.load();
    }

    this.getScreenSize();

    this.initialized = true;
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.showScrollArrows();
  }

  scrollToBottom() {
    document.body.scrollTop = document.body.scrollHeight;
    document.documentElement.scrollTop = document.documentElement.scrollHeight;
    this.showScrollArrows();
  }

  ngOnDestroy(){
    if ( !this.router.paying ) {
      this.clearStorage();
    }
  }

  clearStorage() {
    this.data.clearjwt();
  }
}
