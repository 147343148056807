import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthConfig, OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { DataService }       from '@omnilib/services/data.service';
import { LoadingService }    from '@omnipas/services/loading.service';
import { LoginService }      from '@omnipas/services/login.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { PersonService }     from '@omnipas/services/person.service';
import { RouterService }     from '@omnipas/services/router.service';
import { environment }       from '@environments/omnipas/dev/environment';

import { Observable, throwError, of, from } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'omnipas-surflogin',
  templateUrl: './surflogin.component.html'
})
export class SurfLoginComponent implements OnInit, OnDestroy {

  claims = {};
  initialized: boolean = false;

  constructor ( public data:         DataService
              , public loading:      LoadingService
              , public login:        LoginService
              , public message:      MessageService
              , public oauthService: OAuthService
              , public pageheader:   PageHeaderService
              , public person:       PersonService
              , public router:       RouterService
              ) {

    this.router.loggingin = true;
    this.connect();
  }

  async connect() {
    this.router.loggingin = true;
    this.pageheader.setPageHeader( 'pagetitle.surfconext', 'pagetext.surfconext' );

    let authConfig: AuthConfig = {};

    authConfig.issuer                   = environment.config.surfConfig['issuer'];
    authConfig.clientId                 = environment.config.surfConfig['clientId'];
    authConfig.redirectUri              = environment.config.surfConfig['redirectUri'];
    authConfig.logoutUrl                = environment.config.surfConfig['logoutUrl'];
    authConfig.silentRefreshRedirectUri = environment.config.surfConfig['silentRefreshRedirectUri'];
    authConfig.scope                    = environment.config.surfConfig['scope'];
    authConfig.requireHttps             = false;

    await this.oauthService.configure(authConfig);
    await this.oauthService.setStorage(sessionStorage);

    this.oauthService.loadDiscoveryDocumentAndLogin({
      onTokenReceived: context => {
        this.login.loginsso ( context['accessToken'] );
      }
    });

    this.router.loggingin = false;
  }

  async ngOnInit() {
    if ( document.location.href.endsWith('surflogin')  ) {
      this.submit();
    } else {
      this.router.toggleshowwait( true );
      this.initialized = false;
      this.pageheader.setPageHeader( 'pagetitle.surfconext', 'pagetext.surfconext' );
      this.initialized = true;
      this.router.toggleshowwait( false );
    }
  }

  async submit() {
    await this.oauthService.initImplicitFlow();
  }

  ngOnDestroy() {
    return;
  }

}
