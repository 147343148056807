import { NgModule }                    from '@angular/core';
import { Routes, RouterModule }        from '@angular/router';
import { AppComponent }                from './app.component';
import { ActivateCardComponent }       from '@omnipas/modules/activatecard/activatecard.component';
//import { ADFSLoginComponent }          from '@omnipas/modules/adfslogin/adfslogin.component';
import { BlockCardComponent }          from '@omnipas/modules/blockcard/blockcard.component';
import { CardInfoComponent }           from '@omnipas/modules/cardinfo/cardinfo.component';
import { CardStatusComponent }         from '@omnipas/modules/cardstatus/cardstatus.component';
import { ChargingCardComponent }       from '@omnipas/modules/chargingcard/chargingcard.component';
import { DeblockCardComponent }        from '@omnipas/modules/deblockcard/deblockcard.component';
//import { DeliveryAddressComponent }    from '@omnipas/modules/deliveryaddress/deliveryaddress.component';
import { DigitalCardComponent }        from '@omnipas/modules/digitalcard/digitalcard.component';
import { FatalErrorComponent }         from '@omnipas/modules/fatalerror/fatalerror.component';
import { LicensePlateComponent }       from '@omnipas/modules/licenseplate/licenseplate.component';
import { LoginComponent }              from '@omnipas/modules/login/login.component';
import { LoginCheckComponent }         from '@omnipas/modules/logincheck/logincheck.component';
import { LogoutComponent }             from '@omnipas/modules/logout/logout.component';
//import { NameOnCardComponent }         from '@omnipas/modules/nameoncard/nameoncard.component';
import { PaymentComponent }            from '@omnipas/modules/payment/payment.component';
import { PhotoEditComponent }          from '@omnipas/modules/photoedit/photoedit.component';
import { PhotoPermissionsComponent }   from '@omnipas/modules/photopermissions/photopermissions.component';
import { PhotoResultComponent }        from '@omnipas/modules/photoresult/photoresult.component';
import { RequestCardComponent }        from '@omnipas/modules/requestcard/requestcard.component';
import { RequestPasswordComponent }    from '@omnipas/modules/requestpassword/requestpassword.component';
import { ResetPasswordComponent }      from '@omnipas/modules/resetpassword/resetpassword.component';
//import { SamlComponent }               from '@omnipas/modules/saml/saml.component';
import { SurfLoginComponent }          from '@omnipas/modules/surflogin/surflogin.component';
import { WebcamComponent }             from '@omnipas/modules/webcam/webcam.component';
import { WelcomeComponent }            from '@omnipas/modules/welcome/welcome.component';

const routes: Routes = [
  { path: 'activatecard',                  component: ActivateCardComponent },
//  { path: 'adfslogin',                     component: ADFSLoginComponent },
  { path: 'blockcard',                     component: BlockCardComponent },
  { path: 'cardinfo',                      component: CardInfoComponent },
  { path: 'cardstatus',                    component: CardStatusComponent },
  { path: 'chargingcard',                  component: ChargingCardComponent },
  { path: 'deblockcard',                   component: DeblockCardComponent },
//  { path: 'deliveryaddress',               component: DeliveryAddressComponent },
  { path: 'digitalcard',                   component: DigitalCardComponent },
  { path: 'digitalcard/:token1',           component: DigitalCardComponent },
  { path: 'digitalcard/:token1/:token2',   component: DigitalCardComponent },
  { path: 'error',                         component: FatalErrorComponent },
  { path: 'licenseplate',                  component: LicensePlateComponent },
  { path: 'login',                         component: LoginComponent },
  { path: 'logincheck',                    component: LoginCheckComponent },
  { path: 'logout',                        component: LogoutComponent },
//  { path: 'nameoncard',                    component: NameOnCardComponent },
  { path: 'payment',                       component: PaymentComponent },
  { path: 'payment/:token1/:token2',       component: PaymentComponent },
  { path: 'payment/:token1',               component: PaymentComponent },
  { path: 'photo',                         component: PhotoEditComponent },
  { path: 'photopermissions',              component: PhotoPermissionsComponent },
  { path: 'result',                        component: PhotoResultComponent },
  { path: 'requestcard',                   component: RequestCardComponent },
  { path: 'requestpassword',               component: RequestPasswordComponent },
  { path: 'resetpassword',                 component: ResetPasswordComponent },
  { path: 'resetpassword/:token1',         component: ResetPasswordComponent },
//  { path: 'resetpassword/:token1/:token2', component: ResetPasswordComponent },
//  { path: 'saml',                          component: SamlComponent },
//  { path: 'saml/:token1',                  component: SamlComponent },
  { path: 'surflogin',                     component: SurfLoginComponent },
  { path: 'webcam',                        component: WebcamComponent },
  { path: 'welcome',                       component: WelcomeComponent },
  { path: '',                              redirectTo: 'login', pathMatch: 'full' },
  { path: '**',                            component: LoginComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
