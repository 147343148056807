import { Injectable }              from '@angular/core';
import { JWTService }              from '@omnilib/services/jwt.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError }         from 'rxjs/operators';
import { Observable, from }        from 'rxjs';
import { environment }             from '@environments/omnipas/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  jwtstring: string = '';
  error = { "result": { "code": "-1", "message": "Server Error", "resultset": null } };


  constructor( public  http: HttpClient
             , public  jwt:  JWTService
             ) {
  }

  getjwt() {
    if ( this.jwtstring == '' ) {
      this.jwtstring = this.jwt.get();
    }
    return this.jwtstring;
  }

  setjwt( jwtstring:string = '' ) {
    if ( jwtstring != '' ) {
      this.jwt.set( jwtstring );
      this.jwtstring = jwtstring;
    }
  }

  clearjwt() {
    this.jwt.clear();
    this.jwtstring = '';
  }

  callapi( what, name, params ) {
    if ( name == 'auth/sso' || name == 'auth/authenticate' || name == 'auth/reset-password' || name == 'auth/2fa' || name.indexOf('auth/verify-reset') != -1 ) {
      this.headers = new HttpHeaders({ 'Content-Type': 'application/json'
                                     });
    } else {
      this.jwtstring = this.getjwt();

      if ( this.jwtstring == '' ) {
        this.error;
      } else {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json'
                                       , 'Access-Control-Allow-Origin': '*'
                                       , 'Authorization' : 'Bearer '+ this.jwtstring
                                       });
      }
    }

    let url = ( environment.production ? environment.apiurl : 'rest/' ) + name;

    if ( environment.mock ) {
      url = url.replace('/auth', '');
    }

    switch (what) {
      case 'get':
        return this.get(url);
        break;
      case 'put':
        return this.put(url, params);
        break;
      case 'delete':
        return this.delete(url);
        break;
      case 'post':
      default:
        //return this.post(url, params).pipe(map((data) => data));
        return this.post(url,params);
        break;
    }
  }

  async get(url) {
    try {
      return await this.http.get( url, { headers: this.headers, responseType: 'json' } ).toPromise();
    } catch(e) {
      return this.error;
    }
  }

  getnopromise(name, params ): Observable<any> {
    let url = ( environment.production ? environment.apiurl : 'rest/' ) + name;

    this.jwtstring = this.getjwt();

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json'
                                   , 'Access-Control-Allow-Origin': '*'
                                   , 'Authorization' : 'Bearer '+ this.jwtstring
                                   });
    return this.http.post( url, params, { headers: this.headers, responseType: 'json' } );
  }

  getnopromise2(name): Observable<any> {
    let url = ( environment.production ? environment.apiurl : 'rest/' ) + name;

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json'
                                   , 'Access-Control-Allow-Origin': '*'
                                   });
    return this.http.get( url, { headers: this.headers, responseType: 'json' } );
  }

  async post(url, params) {
    this.headers.set('Access-Control-Allow-Origin', '*');

    try {
      return await this.http.post( url, params, { headers: this.headers, responseType: 'json' } ).toPromise();
    } catch(e) {
      return this.error;
    }
  }

  async put(url, params) {
    try {
      return await this.http.put( url, params, { headers: this.headers, responseType: 'json' } ).toPromise();
    } catch(e) {
      return this.error;
    }
  }

  async delete(url) {
    try {
      return await this.http.delete( url, { headers: this.headers, responseType: 'json' } ).toPromise();
    } catch(e) {
      return this.error;
    }
  }

}
