import { Component, OnInit, OnDestroy } from '@angular/core';
import { IconsService }      from '@omnipas/services/icons.service';
import { LoginService }      from '@omnipas/services/login.service';
import { LoadingService }    from '@omnipas/services/loading.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-logincheck',
  templateUrl: './logincheck.component.html'
})
export class LoginCheckComponent implements OnInit, OnDestroy {

  code:        string  = '';
  initialized: boolean = false;
  eye;

  constructor ( public icons:          IconsService
              , public login:          LoginService
              , public loading:        LoadingService
              , public message:        MessageService
              , public pageheader:     PageHeaderService
              , public router:         RouterService
              ) {
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize() {
    this.router.toggleshowwait( true );
    this.initialized = false;

    this.eye = this.icons.faeye;

    this.pageheader.setPageHeader( 'pagetitle.logincheck', 'pagetext.logincheck' );
    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  togglePassword( bool ) {
    if ( bool ) {
      document.getElementById('password').setAttribute('type','text');
      this.eye = this.icons.faeyeslash;
    } else {
      document.getElementById('password').setAttribute('type','password');
      this.eye = this.icons.faeye;
    }

    document.getElementById('password').classList.toggle('visible');
  }

  submit() {
    if ( this.code != '' ) {
      this.login.logincheck( this.code );
    }
  }

  reset() {
    this.code = '';
  }

  enableSubmit() {
    return this.code != '';
  }

  enableReset() {
    return this.code != '';
  }

  ngOnDestroy() {
    return;
  }

}
