import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Observable, timer } from 'rxjs';
import { map, catchError, switchMap, share } from 'rxjs/operators';
import { LocalStorageService } from '@omnilib/services/localstorage.service';
import { RouterService } from '@omnipas/services/router.service';
import { environment } from '@environments/omnipas/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  initialLanguage = 'nl';
  currentLanguage = 'nl';
  savedlang       = '';
  initialCard     = 'kaart';

  constructor ( public translate: TranslateService
              , public localstorage: LocalStorageService
              , public router: RouterService
              ) {
  }

  initialize() {
    this.savedlang = this.localstorage.getData( 'lang' );
    this.currentLanguage = this.savedlang == undefined || this.savedlang == null || this.savedlang == '' ? this.initialLanguage : this.savedlang;
    this.changeLanguage( this.currentLanguage, '', true );
    //this.router.transparams.card = this.initialCard;
    // this.translate.setDefaultLang(this.currentLanguage);
  }

  changeLanguage( $lang, $card, $overwrite = true ) {
    if ( $lang == '' || !$overwrite ) {
      this.currentLanguage = this.savedlang == undefined || this.savedlang == null || this.savedlang == '' ? this.initialLanguage : this.savedlang;
    } else {
      this.localstorage.saveData ( 'lang', $lang );
      this.currentLanguage = $lang.toLowerCase();
    }

    if ( $card == '' ) {

      this.router.transparams.card = '';

      for ( let l in environment.config.languages ) {
        if ( environment.config.languages[l]['LanguageCode'] == this.currentLanguage ) {
          if ( this.router.transparams.customer_shortname == 'Inholland' ) {
            if ( this.router.persondata['cardholderProfile'] == undefined ) {
              this.router.transparams.card = environment.config.languages[l]['Card']['student'];
            } else {
              this.router.transparams.card = environment.config.languages[l]['Card'][this.router.persondata['cardholderProfile']['Value']];
            }
          } else {
            this.router.transparams.card = environment.config.languages[l]['Card'];
          }
        }
      }

      if ( this.router.transparams.card == '' ) {
        this.router.transparams.card = this.initialCard;
      }

    } else {
      this.router.transparams.card = $card;
    }

    this.translate.setDefaultLang(this.currentLanguage);
  }

  getTranslation( $text, $type: string = 'I' ): string {

    let result: string = '';

    this.translate.get( $text, this.router.transparams ).subscribe((res: string) => {
      result = res;
    });

    switch($type) {
      case "C" :
        return result.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
      case "I" :
        return result[0].toUpperCase() + result.slice(1);
        break;
      case "L" :
        return result.toLowerCase();
        break;
      case "U" :
        return result.toUpperCase();
        break;
      default :
        return result;
        break;
    }
  }

  getImageSrc( img ) {
    let url = 'assets/images/' + ( environment.config.langimage ? ( this.currentLanguage != '' ? this.currentLanguage : this.initialLanguage ) + '/' : '' ) +img;
    return url;
  }
}
