import { Component, OnInit, OnDestroy } from '@angular/core';
import { IconsService }      from '@omnipas/services/icons.service';
import { LoginService }      from '@omnipas/services/login.service';
import { LoadingService }    from '@omnipas/services/loading.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { RouterService }     from '@omnipas/services/router.service';
import { environment }       from '@environments/omnipas/dev/environment';

@Component({
  selector: 'omnipas-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  environment = environment;

  username:    string  = '';
  password:    string  = '';
  initialized: boolean = false;

  eye;

  constructor ( public icons:          IconsService
              , public login:          LoginService
              , public loading:        LoadingService
              , public message:        MessageService
              , public pageheader:     PageHeaderService
              , public router:         RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.router.toggleshowwait( false );
  }

  async initialize() {
    this.initialized = false;

    this.eye = this.icons.faeye;

    await this.loading.load();

    switch( environment.config.logintype ) {
      case 'saml' :
        this.router.goTo( environment.config.logintype );
        break;
      case 'surf' :
      case 'adfs' :
        this.router.goTo( environment.config.logintype + 'login' );
        break;
      case 'autologin':
        this.autologin();
        break;
      default:
        this.pageheader.setPageHeader( 'pagetitle.login', 'pagetext.login' );
        this.initialized = true;
        break;
    }
  }

  autologin() {
    this.login.login( environment.config.username, environment.config.password );
  }

  togglePassword( bool ) {
    if ( bool ) {
      document.getElementById('password').setAttribute('type','text');
      this.eye = this.icons.faeyeslash;
    } else {
      document.getElementById('password').setAttribute('type','password');
      this.eye = this.icons.faeye;
    }

    document.getElementById('password').classList.toggle('visible');
  }

  submit() {
    this.login.login( this.username, this.password );
  }

  reset() {
    this.username = '';
    this.password = '';
  }

  requestNew() {
    this.router.requestPassword();
  }

  enableSubmit() {
    return this.username != '' && this.password != '';
  }

  enableReset() {
    return this.username != '' || this.password != '';
  }

  ngOnDestroy() {
    return;
  }

}
