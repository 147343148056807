import { Component, OnInit, OnDestroy } from '@angular/core';
import { ListService }       from '@omnilib/services/list.service';
import { LoadingService }    from '@omnipas/services/loading.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { PhotoService }      from '@omnipas/services/photo.service';
import { RouterService }     from '@omnipas/services/router.service';
import { environment }       from '@environments/omnipas/dev/environment';

@Component({
  selector: 'omnipas-photoresult',
  templateUrl: './photoresult.component.html'
})
export class PhotoResultComponent implements OnInit, OnDestroy {
  environment = environment;

  initialized: boolean     = false;
  deliveryaddress: boolean = false;

  constructor ( public list:       ListService
              , public loading:    LoadingService
              , public message:    MessageService
              , public pageheader: PageHeaderService
              , public photo:      PhotoService
              , public router:     RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.photoresult' );
    }

    this.deliveryaddress  = this.message.success
                         && environment.config.deliveryaddress
                         && this.photo.firstphoto
                         && ( this.list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase() == 'student' );

    this.initialized     = true;
    this.router.toggleshowwait( false );
  }

  logout() {
    this.router.logout();
  }

  back() {
    this.router.goTo('photo');
  }

  continue() {
    this.router.goTo('deliveryaddress');
  }

  ngOnDestroy() {
    return;
  }

}
