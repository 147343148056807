import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { DataService } from '@omnilib/services/data.service';
import { LanguageService } from '@omnipas/services/language.service';
import { PersonService } from '@omnipas/services/person.service';
import { RouterService } from '@omnipas/services/router.service';
import { Title } from '@angular/platform-browser'
import { environment } from '@environments/omnipas/dev/environment';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor(
    public data: DataService,
    public http: HttpClient,
    public language: LanguageService,
    public person: PersonService,
    public router: RouterService,
    public title: Title
  ) {}

  async load( payment = false ) {
    this.router.toggleshowwait( true );
    this.language.initialize();
    this.router.transparams.product            = environment.config.product;
    this.router.transparams.customer_fullname  = environment.config.customer_fullname;
    this.router.transparams.customer_shortname = environment.config.customer_shortname;
    this.router.transparams.url                = environment.config.url;

    if ( this.router.transparams.product != '' ) {
      this.title.setTitle( this.router.transparams.product );
    }

    if ( this.data.getjwt() != '' ) {
      if ( await this.person.getPersonData() ) {
        //if ( !payment ) {
        //  this.router.goHome();
        //}
      }
    }
    this.router.toggleshowwait( false );
  }
}
