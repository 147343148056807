import { Component, OnInit, OnDestroy }   from '@angular/core';
import { CardService }         from '@omnipas/services/card.service';
import { ListService }         from '@omnilib/services/list.service';
import { LoadingService }      from '@omnipas/services/loading.service';
import { MessageService }      from '@omnipas/services/message.service';
import { PageHeaderService }   from '@omnipas/services/pageheader.service';
import { RouterService }       from '@omnipas/services/router.service';
import { VehicleDataService }  from '@omnipas/services/vehicledata.service';
import { environment }         from '@environments/omnipas/dev/environment';

@Component({
  selector: 'omnipas-licenseplate',
  templateUrl: './licenseplate.component.html'
})
export class LicensePlateComponent implements OnInit, OnDestroy {

  initialized: boolean = false;

  constructor ( public card:         CardService
              , public list:         ListService
              , public loading:      LoadingService
              , public message:      MessageService
              , public pageheader:   PageHeaderService
              , public router:       RouterService
              , public vehicledata:  VehicleDataService
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( (!environment.config.licenseplate) || this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      await this.vehicledata.get();
      this.pageheader.setPageHeader ( 'pagetitle.licenseplate', 'pagetext.licenseplate' );
    }

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  keyup() {
    this.vehicledata.licenseplate = this.vehicledata.licenseplate.toUpperCase();
  }

  async submit() {
    this.router.toggleshowwait(true);

    if ( await this.vehicledata.update() ) {
      this.message.setSuccessMessage ( 'licenseplate.result.success', false, true );
    } else {
      this.message.setErrorMessage ( 'licenseplate.result.failure', false, true );
    }

    this.router.toggleshowwait(false);
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }

}
