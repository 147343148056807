import { Component, OnInit, OnDestroy } from '@angular/core';
import { LanguageService }   from '@omnipas/services/language.service';
import { ListService }       from '@omnilib/services/list.service';
import { RouterService }   from '@omnipas/services/router.service';
import { environment }      from '@environments/omnipas/dev/environment';

@Component({
  selector: 'omnipas-languagetoggle',
  templateUrl: './languagetoggle.component.html'
})
export class LanguageToggleComponent implements OnInit, OnDestroy {
  environment = environment;
  
  constructor ( public language: LanguageService
              , public list:     ListService
              , public router:   RouterService
              ) {
  }

  ngOnInit() {
  }

  checkCurrentLanguage($lang) {
    if ( this.language.currentLanguage == $lang.toLowerCase() ) {
      return 'hide';
    } else {
      return 'show fullwidth';
    }
  }

  ngOnDestroy() {
    return;
  }
}
