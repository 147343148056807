import { Injectable }          from '@angular/core';
import { DataService }         from '@omnilib/services/data.service';
import { LanguageService }     from '@omnipas/services/language.service';
import { ListService }         from '@omnilib/services/list.service';
import { LoadingService }      from '@omnipas/services/loading.service';
import { LocalStorageService } from '@omnilib/services/localstorage.service';
import { RouterService }       from '@omnipas/services/router.service';
import { environment }         from '@environments/omnipas/dev/environment';

import CryptoES from 'crypto-es';
import { format, toDate  }  from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PaymentService  {

  ingenico          = {};
  url:       string = '';

  constructor( public data:         DataService
             , public language:     LanguageService
             , public list:         ListService
             , public loading:      LoadingService
             , public localstorage: LocalStorageService
             , public router:       RouterService
             ) {
  }

  async shasign( shastring ) {
    return CryptoES.SHA1( shastring ).toString().toUpperCase();
  }

  async getSign( $fields, $sign ) {
    let shastring: string = '';

    Object.keys($fields).sort().forEach(function(key) {
      if ( $fields[key] != '' ) {
        shastring += `${key}=${$fields[key]}${$sign}`;
      }
    });

    return await this.shasign( shastring );
  }

  getLanguagueCode( $lang ){
    switch($lang) {
      case 'eng' :
      case 'en'  :
        return 'en_US';
        break;
      case 'nl'  :
      case 'nld' :
      default    :
        return 'nl_NL';
        break;
    }
  }

  getAddress() {
    let address = '';
    address += this.list.getValue( this.router.persondata, 'street', true ) + ' ' + this.list.getValue( this.router.persondata, 'number', true ) + ' ' + this.list.getValue( this.router.persondata, 'suffix', true );
    return address.trim();
  }

  async initialize() {
    let shasign: string  = '';
    this.url       = this.list.getValue( environment['config']['ingenico'], 'url', true );

    this.ingenico = {
      'PSPID'          : this.list.getValue( environment['config']['ingenico'], 'pspid', true ),
      'ORDERID'        : this.createOrderID(),
      'AMOUNT'         : this.router.amount,
      'CURRENCY'       : this.list.getValue( environment['config']['ingenico'], 'currency', true ),
      'LANGUAGE'       : this.getLanguagueCode( this.language.currentLanguage ),
      'CN'             : this.router.transparams.fullname,
      'EMAIL'          : this.list.getValue( this.router.persondata, 'email', true ),
      'OWNERZIP'       : this.list.getValue( this.router.persondata, 'postalCode', true ),
      'OWNERADDRESS'   : this.getAddress(),
      'OWNERCTY'       : this.list.getValue( this.router.persondata, 'city', true ),
      'OWNERTOWN'      : this.list.getValue( this.router.persondata, 'city', true ),
      'COM'            : "",
      'TITLE'          : this.language.getTranslation('payment.requestNewCard'),
      'BGCOLOR'        : this.list.getValue( environment['config']['ingenico'], 'bgcolor', true ),
      'TXTCOLOR'       : this.list.getValue( environment['config']['ingenico'], 'txtcolor', true ),
      'TBLBGCOLOR'     : this.list.getValue( environment['config']['ingenico'], 'tblbgcolor', true ),
      'TBLTXTCOLOR'    : this.list.getValue( environment['config']['ingenico'], 'tbltxtcolor', true ),
      'BUTTONBGCOLOR'  : this.list.getValue( environment['config']['ingenico'], 'buttonbgcolor', true ),
      'BUTTONTXTCOLOR' : this.list.getValue( environment['config']['ingenico'], 'buttontxtcolor', true ),
      'FONTTYPE'       : this.list.getValue( environment['config']['ingenico'], 'fonttype', true ),
      'PM'             : this.list.getValue( environment['config']['ingenico'], 'pm', true ),
      'HOMEURL'        : this.list.getValue( environment['config']['ingenico'], 'homeurl', true ),
      'ACCEPTURL'      : this.list.getValue( environment['config']['ingenico'], 'accepturl', true ),
      'DECLINEURL'     : this.list.getValue( environment['config']['ingenico'], 'declineurl', true ),
      'EXCEPTIONURL'   : this.list.getValue( environment['config']['ingenico'], 'exceptionurl', true ),
      'CANCELURL'      : this.list.getValue( environment['config']['ingenico'], 'cancelurl', true ),
      'SHASIGN'        : ''
    };

    shasign = await this.getSign( this.ingenico, this.list.getValue( environment['config']['ingenico'], 'sha_in_key', true ) );

    this.ingenico['SHASIGN'] = shasign;
  }

  createOrderID() {
    let orderid = this.list.getValue( environment['config']['ingenico'], 'orderid', true );

    return ( orderid == '' ? 'CARDREQUEST_' : orderid )
         + format(toDate(new Date()),'ddMMyyyyHHmmss')
         + '&_&'
         + this.list.getValue( this.router.persondata, 'cardholderID', true )
    ;
  }

  async checkSignOut( $fields, $sign ) {
    let shasign:   string = '';
    let shastring: string = '';

    let cleanfields = {};

    Object.keys($fields).forEach(function(key) {
     cleanfields[decodeURIComponent(key).toUpperCase()] = decodeURIComponent($fields[key]);
    });

    Object.keys(cleanfields).sort().forEach(function(key) {
      if ( key == 'SHASIGN' ) {
        shasign = cleanfields[key];
      } else if ( cleanfields[key] != '' ) {
        shastring += `${key.toUpperCase()}=${cleanfields[key]}${$sign}`;
      }
    });

    //this.log.log ( 'checksign ingenico ' + shasign, 3 );
    //this.log.log ( 'checksign omnipas ' + await this.shasign(shastring), 3 );

    return shasign == await this.shasign(shastring);
  }

  async updatecardholdernote ( note ) {
    if ( note != '' && !this.list.emptyList( this.router.persondata ) ) {

      let oldnote = this.list.getValue( this.router.persondata, 'note', true );

      let newnote = ( oldnote != '' ? oldnote + '\n\n' : '' ) + format(toDate(new Date()),'yyyy-MM-dd HH:mm:ss') + ' ' + note;

      let params = { 'Note' : newnote
                   , 'StatusId' : this.list.getValue( this.router.persondata, 'cardStatus', true )
                   };

      //let result = await this.data.callapi( 'post', 'card/update', params );

      //console.log ( result );

    }
  }

}
