import { Injectable } from '@angular/core';
import { DataService }       from '@omnilib/services/data.service';
import { ListService }       from '@omnilib/services/list.service';
import { LoadingService }    from '@omnipas/services/loading.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { PersonService }     from '@omnipas/services/person.service';
import { RouterService }     from '@omnipas/services/router.service';
import { environment }       from '@environments/omnipas/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleDataService {
  constructor( public data:           DataService
             , public list:           ListService
             , public loading:        LoadingService
             , public message:        MessageService
             , public pageheader:     PageHeaderService
             , public person:         PersonService
             , public router:         RouterService
             ) {
  }

  countrycode:        string = 'NL';
  licenseplate:       string = '';
  chargingcardnumber: string = '';

  found:              boolean = false;

  async get() {
    let result = await this.data.callapi ( 'get', 'freefield/person', {} );

    if ( result['result']['code'] && result['result']['code'] == '0' && !this.list.emptyList( result['result']['resultset'] ) ) {
      this.found              = true;
      this.countrycode        = this.list.getValue( result['result']['resultset'], 'freeField01', true );
      this.licenseplate       = this.list.getValue( result['result']['resultset'], 'freeField02', true );
      this.chargingcardnumber = this.list.getValue( result['result']['resultset'], 'freeField03', true );
    }
  }

  async update() {
    let params = { "freeField01" : this.countrycode
                 , "freeField02" : this.licenseplate
                 , "freeField03" : this.chargingcardnumber
                 }


    let result = await this.data.callapi ( this.found ?  'put' : 'post', 'freefield/person', params );

    if ( result['result']['code'] && result['result']['code'] == '0' ) {
      return true;
    } else {
      return false;
    }
  }
}
