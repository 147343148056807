import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
import { DataService }       from '@omnilib/services/data.service';
import { IconsService }      from '@omnipas/services/icons.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  initialized:     boolean = false;
  password:        string  = '';
  repeatpassword:  string  = '';
  invalidLink:     boolean = false;
  token:           string  = '';
  eye;
  eyerepeat;

  constructor ( public  data:       DataService
              , public  icons:      IconsService
              , public  message:    MessageService
              , public  router:     RouterService
              , public  pageheader: PageHeaderService
              , public  route:      ActivatedRoute
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.router.toggleshowwait( false );
  }

  async initialize() {
    this.initialized     = false;
    this.password        = '';
    this.repeatpassword  = '';

    this.eye       = this.icons.faeye;
    this.eyerepeat = this.icons.faeye;

    const params = await this.route.params['value'];

    if ( params['token1'] && params['token1'] != '' ) {
      this.token = params['token1'];
      this.invalidLink = false;
    } else {
      this.message.setErrorMessage( 'resetpassword.error.invalid' );
      this.invalidLink = true;
    }

    if ( this.message.error ) {
      this.pageheader.setPageHeader( 'pagetitle.resetpassword', '' );
    } else {
      this.pageheader.setPageHeader( 'pagetitle.resetpassword', 'pagetext.resetpassword' );
    }

    this.initialized     = true;
  }

  togglePassword( bool ) {
    if ( bool ) {
      document.getElementById('password').setAttribute('type','text');
      this.eye = this.icons.faeyeslash;
    } else {
      document.getElementById('password').setAttribute('type','password');
      this.eye = this.icons.faeye;
    }

    document.getElementById('password').classList.toggle('visible');
  }

  toggleRepeatPassword( bool ) {
    if ( bool ) {
      document.getElementById('repeatpassword').setAttribute('type','text');
      this.eyerepeat = this.icons.faeyeslash;
    } else {
      document.getElementById('repeatpassword').setAttribute('type','password');
      this.eyerepeat = this.icons.faeye;
    }

    document.getElementById('repeatpassword').classList.toggle('visible');
  }

  reset() {
    this.password       = '';
    this.repeatpassword = '';
  }

  async submit() {
    if ( this.password != '' && this.repeatpassword != '' && this.password == this.repeatpassword ) {
      let params =  { 'password' : this.password };
      let result = await this.data.callapi ( 'post', 'auth/verify-reset/'+this.token, params );
        if ( result['result']['code'] && result['result']['code'] == '0' ) {
            this.message.setSuccessMessage ( 'resetpassword.success' );
            this.pageheader.setPageHeader( 'pagetitle.resetpassword' );
        } else {
          this.message.setErrorMessage ( 'resetpassword.error.fail', true );
        }
    } else {
      this.message.setErrorMessage ( 'resetpassword.error.incorrect', true );
    }
  }

  enableReset() {
    return this.password != '' || this.repeatpassword != '';
  }

  enableSubmit() {
    return this.password != '' && this.repeatpassword != '' && this.password == this.repeatpassword;
  }

  ngOnDestroy() {
    return;
  }

}
