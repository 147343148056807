<div id="moreinfocontent" *ngIf="router.transparams.customer_shortname != ''" class="w3-modal-content w3-display-content w3-display-container w3-animate-zoom w3-card-4 w3-theme-l4">
  <fa-icon id="icon-close" [icon]="icons.fatimes" class="w3-display-topright w3-hover-shadow close" (click)="close()" title="{{ 'buttons.close' | translate: router.transparams }}"></fa-icon>
  <div class="w3-container w3-margin-top">
    <p innerHTML="{{ 'moreinfo.blocks.1' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.2' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.3' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.4' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.5' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.6' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.7' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.8' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.9' | translate: router.transparams }}"></p>
    <p innerHTML="{{ 'moreinfo.blocks.10' | translate: router.transparams }}"></p>
  </div>
</div>
