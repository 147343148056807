import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor () {
  }

  emptyList(obj) {
    for ( var i in obj ) {
      return false;
    }
    return true;
  }

  fieldExists( $obj, $field, $listChecked = false ) {
    if ( !$listChecked ) {
      if ( this.emptyList($obj) ) {
        return false;
      }
    }

    if ( typeof $obj == 'object' ) {
      if ( $field in $obj ) {
        return true;
      }
    }

    return false;
  }

  getString ( $field ) {
    if ( typeof $field != 'object' ) {
      return $field;
    } else {
      return '';
    }
  }

  getValue ( $obj, $field, $listChecked = false, $boolean = false ) {
    let value;

    if ( this.fieldExists ( $obj, $field, $listChecked ) ) {
      if ( this.fieldExists( $obj[$field], 'Value', false ) ) {
        value = $obj[$field]['Value'];
      } else {
        value = this.getString($obj[$field]);
      }
    }

    return $boolean ? ( value == '1'  ? 'True' : value == '0' ? 'False' : '' ) : value;
  }

  setValue ( $obj, $field, $value ) {
    if ( this.fieldExists ( $obj, $field, false ) ) {
      if ( this.fieldExists( $obj[$field], 'Value', true ) ) {
        $obj[$field]['Value'] = $value;
      } else {
        $obj[$field] = $value;
      }
    } else {
      $obj[$field]['Value'] = $value;
    }
  }
}
